import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import RegistudyFeatures from "../sections/registudy-features"

const RegistudyPage = () => (
  <Layout>
    <SEO title="Registudy" />
    <Container>
      <RegistudyFeatures />
    </Container>
  </Layout>
)

export default RegistudyPage
